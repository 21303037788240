import React, { useContext, useEffect, useState } from 'react';
import { KatButton, KatDropdown, KatIcon, KatModal } from '@amzn/katal-react';
import {
  RedeployConfig,
  useModelRedeployment,
} from 'src/hooks/useModelRedeployment/useModelRedeployment';
import { ApiContext } from 'src/context/apiContext';
import { Link } from 'react-router-dom';

export const RedeployModal = ({
  redeployConfig,
  setRedeployConfig,
}: {
  redeployConfig: RedeployConfig | null;
  setRedeployConfig: (arg: RedeployConfig | null) => void;
}) => {
  const { state } = useContext(ApiContext);
  const [loading, requestRedeployment] = useModelRedeployment();
  const [requestError, setRequestError] = useState<Error | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string>('');

  const [requestedRedeployment, setRequestedRedeployment] =
    useState<boolean>(false);

  useEffect(() => {
    if (!redeployConfig) return;
    if (redeployConfig?.regions && redeployConfig.regions.length === 1) {
      setSelectedRegion(redeployConfig.regions[0]);
    }
  }, [redeployConfig]);

  const resetModal = () => {
    setRedeployConfig(null);
    setRequestedRedeployment(false);
  };

  return (
    <>
      {requestedRedeployment ? (
        <KatModal
          onClose={resetModal}
          visible={redeployConfig !== null}
          title="Success"
          footer={
            <>
              <KatButton
                variant="link"
                onClick={() => {
                  resetModal();
                }}
              >
                Stay here
              </KatButton>
              <Link
                to={`/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${state.search.curModelVersionId}/modelArtifacts/${state.search.curModelArtifactId}/operatorFlowJobs`}
              >
                <KatButton>View Operator Flows</KatButton>
              </Link>
            </>
          }
        >
          <div className="redirect-alert__content">
            <KatIcon name="checkmark-circle" />
            <div>
              <p>
                Successfully requested a model redeployment. In a few moments
                you should be able to view the new model in SageMaker and in
                MLRoller (with &quot;-redeploy&quot; suffix). If you are
                redeploying a deployment job with &quot;online&quot; inference
                type, your SageMaker endpoint will also be updated.
              </p>
              <p>
                Click the button below to return to the operator flows page.
              </p>
            </div>
          </div>
        </KatModal>
      ) : (
        <KatModal
          onClose={resetModal}
          visible={redeployConfig !== null}
          title="Request Redeployment"
          footer={
            <>
              <KatButton
                variant="link"
                onClick={() => {
                  resetModal();
                }}
              >
                Cancel
              </KatButton>
              <KatButton
                loading={loading}
                onClick={async () => {
                  /* istanbul ignore next (cant click the button if the modal is not visible) */
                  if (!redeployConfig) return;
                  const result = await requestRedeployment({
                    operatorName: redeployConfig.operatorName,
                    rollbackOperatorFlowId:
                      redeployConfig.rollbackOperatorFlowId,
                    selectedRegion,
                  });
                  setRequestError(result?.error ? result.error : null);
                  setRequestedRedeployment(!!result?.success);
                }}
              >
                Request Redeployment
              </KatButton>
            </>
          }
        >
          <>
            {redeployConfig?.regions && redeployConfig.regions.length > 1 ? (
              <>
                <p>
                  Redeploying will create a{' '}
                  <strong>new model deployment</strong> in the selected region,
                  using the settings from this deployment.
                </p>
                <KatDropdown
                  className="min-h-10"
                  label="Region"
                  value={redeployConfig.regions[0]}
                  options={redeployConfig.regions.map((region) => ({
                    name: region,
                    value: region,
                  }))}
                  onChange={(e: CustomEvent) => {
                    setSelectedRegion(e.detail.value);
                  }}
                />
              </>
            ) : (
              <p>
                Redeploying will create a <strong>new model deployment</strong>{' '}
                {selectedRegion ? (
                  <span>
                    in
                    <strong> {selectedRegion}</strong>,
                  </span>
                ) : (
                  ''
                )}{' '}
                using the settings from this deployment. The redeployed
                SageMaker model (and endpoint config, if the deploy mode is
                &apos;online&apos;) will have &apos;-redeploy&apos; appended to
                their names.
              </p>
            )}
            {requestError && (
              <p className="error-text">{requestError.message}</p>
            )}
          </>
        </KatModal>
      )}
    </>
  );
};
