import { useState } from 'react';
import { apiGetRequest } from 'src/utils/mlRollerAPIRequest/mlRollerAPIRequest';

export interface SageMakerPipelineStatusData {
  stepName: string;
  stepStatus: string;
  failureReason?: string;
}

export const useGetSageMakerPipelineGraphStatus = (): [
  boolean,
  Error | null,
  SageMakerPipelineStatusData[] | null,
  ({ jobId }: { jobId: string }) => void,
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<SageMakerPipelineStatusData[] | null>(null);

  const getStatus = ({ jobId }: { jobId: string }) => {
    setLoading(true);
    setError(null);
    return apiGetRequest({
      endpoint: `sageMakerPipelineExecutionSteps/${jobId}`,
    })
      .then((res) => {
        setData(JSON.parse(res.response));
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };
  return [loading, error, data, getStatus];
};
