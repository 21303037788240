import { QueryClient, useQuery } from '@tanstack/react-query';
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from 'src/utils/mlRollerAPIRequest/mlRollerAPIRequest';
import { urlList } from 'src/context/url';

interface Props {
  account: string | undefined;
}

export const useApplications = ({ account }: Props) => {
  if (!account) return { data: [], isLoading: false };
  return useQuery<Application[], Error>({
    queryKey: ['applications', account],
    queryFn: () =>
      apiGetRequest({
        endpoint: urlList.applications,
        params: {
          awsAccountId: account,
        },
      }),
  });
};

type CreateApplicationProps = {
  account: string;
  application: Pick<
    Application,
    'description' | 'name' | 'roleArn' | 's3ScriptFileLocation'
  >;
  version?: number;
};

export const getApplication = ({
  account,
  id,
}: {
  account?: string;
  id?: string;
}) => {
  if (!account || !id)
    return { data: undefined, isLoading: false, error: undefined };
  return useQuery<Application, Error>({
    queryKey: ['applications', id],
    queryFn: () =>
      apiGetRequest({
        endpoint: `${urlList.applications}/${id}`,
        params: {},
      }),
  });
};

export const createApplication = (
  { application, account }: CreateApplicationProps,
  queryClient: QueryClient,
) => {
  return apiPostRequest({
    endpoint: urlList.applications,
    data: Object.assign(application, { awsAccountId: account }),
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['applications', account],
    });
  });
};

export const editApplication = (
  {
    application,
    account,
    id,
    version,
  }: CreateApplicationProps & { id: string },
  queryClient: QueryClient,
) => {
  return apiPutRequest({
    endpoint: urlList.applications,
    data: Object.assign(application, { awsAccountId: account, id, version }),
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['applications'],
    });
  });
};

export const deleteApplication = (
  { id, account }: { id: string; account: string },
  queryClient: QueryClient,
) => {
  return apiDeleteRequest({
    endpoint: `${urlList.applications}/${id}`,
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['applications', account],
    });
  });
};
