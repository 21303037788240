import React, { ReactElement } from 'react';

const SiteLogo = ({ className }: { className?: string }): ReactElement => {
  return (
    <svg viewBox="0 0 550 180" version="1.1" className={className}>
      <title>ML Roller</title>
      <defs>
        <polygon id="path-1" points="0 0 165 0 165 179 0 179"></polygon>
        <polygon
          id="path-3"
          points="17.8424096 0 237 17.8424096 219.160279 237 0 219.160279"
        ></polygon>
        <polygon
          id="path-5"
          points="236.997312 17.8397207 17.8397215 0 -2.84217094e-14 219.15759 219.15759 236.997311"
        ></polygon>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="mlroller_logo_white" transform="translate(0.000000, 0.000000)">
          <g
            id="Group"
            transform="translate(184.183232, 0.069544)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-1">
              <path
                d="M60.84375,0 L76.625,0 C80.5625,0 82.601562,2.042969 82.75,6.125 L85.640625,74.0625 L70.1875,74.0625 L67.71875,12.78125 L65.890625,12.78125 L54.734375,58.609375 C54.015625,62.109375 51.796875,63.859375 48.078125,63.859375 L37.34375,63.859375 C33.625,63.859375 31.40625,62.109375 30.6875,58.609375 L19.421875,12.78125 L17.59375,12.78125 L15.453125,74.0625 L0,74.0625 L2.671875,6.125 C2.890625,2.042969 4.929688,0 8.796875,0 L24.578125,0 C28.078125,0 30.222656,1.757812 31.015625,5.265625 L40.03125,42.1875 C40.601562,44.554688 41.242188,48.023438 41.953125,52.59375 L43.359375,52.59375 C43.421875,52.242188 43.6875,50.777344 44.15625,48.203125 C44.625,45.621094 45,43.617188 45.28125,42.1875 L54.1875,5.265625 C55.050781,1.757812 57.269531,0 60.84375,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(283.102622, 0.069544)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-2">
              <path
                d="M16.3125,0 L16.3125,55.28125 C16.3125,57.210938 16.757813,58.605469 17.65625,59.46875 C18.550781,60.324219 20.035156,60.75 22.109375,60.75 L47.546875,60.75 L48.296875,73.203125 C41.066406,73.9960938 30.617187,74.390625 16.953125,74.390625 C11.234375,74.390625 6.976563,72.960938 4.1875,70.09375 C1.394531,67.230469 0,63.402344 0,58.609375 L0,0 L16.3125,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(185.573857, 102.616410)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-3">
              <path
                d="M0,75.015625 L0,0.953125 C6.65625,0.320313 16.03125,0 28.125,0 C38.5,0 45.597656,1.683594 49.421875,5.046875 C53.242187,8.402344 55.15625,14.765625 55.15625,24.140625 C55.15625,33.515625 53.347656,39.902344 49.734375,43.296875 C46.128906,46.695313 39.640625,48.390625 30.265625,48.390625 C25.546875,48.390625 20.894531,48.109375 16.3125,47.546875 L16.3125,75.015625 L0,75.015625 Z M16.3125,35.84375 L27.796875,35.84375 C32.015625,35.773437 34.894531,34.949219 36.4375,33.375 C37.976563,31.804687 38.75,28.726563 38.75,24.140625 C38.75,19.558594 37.976563,16.480469 36.4375,14.90625 C34.894531,13.335937 32.015625,12.546875 27.796875,12.546875 L16.3125,12.65625 L16.3125,35.84375 Z M 32.3125, 47.695313 L 60, 75.015625 L 44, 75.015625 L 43, 74, 10, 44 Z"
                id="Shape"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(251.454191, 103.569535)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-4">
              <path
                d="M6.921875,8.21875 C11.535156,2.742187 19.78125,0 31.65625,0 C43.53125,0 51.789063,2.742187 56.4375,8.21875 C61.09375,13.6875 63.421875,23.757813 63.421875,38.421875 C63.421875,53.089844 61.09375,63.164063 56.4375,68.640625 C51.789063,74.109375 43.53125,76.84375 31.65625,76.84375 C19.78125,76.84375 11.535156,74.109375 6.921875,68.640625 C2.304687,63.164063 0,53.089844 0,38.421875 C0,23.757813 2.304687,13.6875 6.921875,8.21875 Z M43.09375,18.46875 C41.050781,14.960937 37.238281,13.203125 31.65625,13.203125 C26.070313,13.203125 22.257813,14.960937 20.21875,18.46875 C18.1875,21.96875 17.171875,28.621094 17.171875,38.421875 C17.171875,48.226563 18.1875,54.882813 20.21875,58.390625 C22.257813,61.890625 26.070313,63.640625 31.65625,63.640625 C37.238281,63.640625 41.050781,61.890625 43.09375,58.390625 C45.132813,54.882813 46.15625,48.226563 46.15625,38.421875 C46.15625,28.621094 45.132813,21.96875 43.09375,18.46875 Z"
                id="Shape"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(325.316146, 102.178910)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-5">
              <path
                d="M16.3125,0 L16.3125,55.28125 C16.3125,57.210938 16.757813,58.605469 17.65625,59.46875 C18.550781,60.324219 20.035156,60.75 22.109375,60.75 L47.546875,60.75 L48.296875,73.203125 C41.066406,73.9960938 30.617187,74.390625 16.953125,74.390625 C11.234375,74.390625 6.976563,72.960938 4.1875,70.09375 C1.394531,67.230469 0,63.402344 0,58.609375 L0,0 L16.3125,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(381.350784, 103.257035)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-6">
              <path
                d="M16.3125,0 L16.3125,55.28125 C16.3125,57.210938 16.757813,58.605469 17.65625,59.46875 C18.550781,60.324219 20.035156,60.75 22.109375,60.75 L47.546875,60.75 L48.296875,73.203125 C41.066406,73.9960938 30.617187,74.390625 16.953125,74.390625 C11.234375,74.390625 6.976563,72.960938 4.1875,70.09375 C1.394531,67.230469 0,63.402344 0,58.609375 L0,0 L16.3125,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(431.325694, 102.178910)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-7">
              <path
                d="M49.90625,61.609375 L50.65625,73.625 C43.925781,74.34375 32.476562,74.703125 16.3125,74.703125 C11.375,74.703125 7.4375,73.359375 4.5,70.671875 C1.5625,67.984375 0.0625,64.355469 0,59.78125 L0,14.921875 C0.0625,10.339844 1.5625,6.710938 4.5,4.03125 C7.4375,1.34375 11.375,0 16.3125,0 C32.476562,0 43.925781,0.355469 50.65625,1.0625 L49.90625,13.203125 L21.453125,13.203125 C19.671875,13.203125 18.382812,13.636719 17.59375,14.5 C16.8125,15.355469 16.421875,16.746094 16.421875,18.671875 L16.421875,29.9375 L45.9375,29.9375 L45.9375,41.75 L16.421875,41.75 L16.421875,56.015625 C16.421875,58.027344 16.8125,59.464844 17.59375,60.328125 C18.382812,61.183594 19.671875,61.609375 21.453125,61.609375 L49.90625,61.609375 Z"
                id="Path"
              ></path>
            </g>
          </g>
          <g
            id="Group"
            transform="translate(497.185547, 103.569535)"
            fill="#FFFFFF"
            fillRule="nonzero"
          >
            <g id="glyph0-8">
              <path
                d="M0,75.015625 L0,0.953125 C6.65625,0.320313 16.03125,0 28.125,0 C38.5,0 45.597656,1.683594 49.421875,5.046875 C53.242187,8.402344 55.15625,14.765625 55.15625,24.140625 C55.15625,33.515625 53.347656,39.902344 49.734375,43.296875 C46.128906,46.695313 39.640625,48.390625 30.265625,48.390625 C25.546875,48.390625 20.894531,48.109375 16.3125,47.546875 L16.3125,75.015625 L0,75.015625 Z M16.3125,35.84375 L27.796875,35.84375 C32.015625,35.773437 34.894531,34.949219 36.4375,33.375 C37.976563,31.804687 38.75,28.726563 38.75,24.140625 C38.75,19.558594 37.976563,16.480469 36.4375,14.90625 C34.894531,13.335937 32.015625,12.546875 27.796875,12.546875 L16.3125,12.65625 L16.3125,35.84375 Z M 32.3125, 47.695313 L 60, 75.015625 L 44, 75.015625 L 43, 74, 10, 44 Z"
                id="Shape"
              ></path>
            </g>
          </g>
          <g id="Clipped" transform="translate(0.000000, -0.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Path"></g>
            <g mask="url(#mask-2)">
              <g transform="translate(-35.000000, -29.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <g id="Path" stroke="none" fill="none"></g>
                <g
                  id="Clipped"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  mask="url(#mask-4)"
                >
                  <g transform="translate(-0.223131, -0.016130)">
                    <mask id="mask-6" fill="white">
                      <use xlinkHref="#path-5"></use>
                    </mask>
                    <g id="Path" stroke="none" fill="none"></g>
                    <path
                      d="M184.526594,126.466849 C165.3911,93.774135 57.721015,77.7409676 39.8463455,29.579634 C12.8152424,93.8063949 133.52639,130.657947 133.52639,130.657947 C133.52639,130.657947 58.3662129,110.801983 37.5666464,78.3969188 C27.0284143,128.453521 119.063204,159.382694 119.063204,159.382694 C119.063204,159.382694 92.7525719,157.401399 58.3420182,133.840923 C78.0931385,197.341836 199.718316,207.627366 199.718316,207.627366 C199.718316,207.627366 195.833687,145.785149 184.529283,126.466849"
                      id="Path"
                      stroke="none"
                      fill="#00ACCD"
                      fillRule="nonzero"
                      mask="url(#mask-6)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SiteLogo;
