import React, { useContext } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { ArtifactLinkGroupColumn } from 'src/components/ModelArtifactTable/columns/ArtifactLinkGroupColumn/ArtifactLinkGroupColumn';
import { DataTable } from 'src/components/DataTable/DataTable';

export function ModelArtifactTable() {
  const { state, dispatch } = useContext(ApiContext);
  const columns = [
    {
      Header: 'Model Artifact Name',
      accessor: 'modelArtifactName',
      id: 'model-artifact-name',
      sortable: true,
    },
    {
      Header: 'Model Artifact ID',
      accessor: 'id',
      id: 'model-artifact-id',
      sortable: true,
    },
    // for the operator flow / metrics links
    ArtifactLinkGroupColumn({ state, dispatch }),
  ];

  return (
    <DataTable
      heading="Model Artifacts"
      description={
        <>
          <p>
            MLRoller Model Artifacts are a collection of operator flows. You can
            view the aggregated metrics for all flows in an artifact, for both
            model metrics and data quality metrics (if enabled).
          </p>
          <p>SageMaker Model Artifacts are not related.</p>
        </>
      }
      data={state.modelArtifacts.data}
      columns={columns}
      options={{
        noDataText:
          'Existing or New Model Artifacts will be listed here when an operator flow begins.',
        defaultPageSize: 10,
      }}
    />
  );
}
