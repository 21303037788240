import React, { useState } from 'react';
import cx from 'classnames';
import { RedeployConfig } from 'src/hooks/useModelRedeployment/useModelRedeployment';
import { RetryConfig } from 'src/hooks/useRetryOperator/useRetryOperator';
import { PromoteModal } from 'src/components/modals/PromoteModal/PromoteModal';
import { ApprovalDecisionModal } from 'src/components/modals/ApprovalDecisionModal';
import { RedeployModal } from 'src/components/modals/RedeployModal/RedeployModal';
import { RetryModal } from 'src/components/modals/RetryModal/RetryModal';
import { PromoteConfig } from 'src/hooks/useManualDeployment/useManualDeployment';
import { Tab, Tabs } from 'src/components/Tabs/Tabs';
import { useGetSageMakerPipelineGraph } from 'src/hooks/useGetSageMakerPipelineGraph/useGetSageMakerPipelineGraph';
import { SageMakerPipelineGraph } from 'src/components/SageMakerPipelineGraph/SageMakerPipelineGraph';
import { SlideOutPanel } from '../SlideOutPanel/SlideOutPanel';
import { OperatorFlowGraph } from '../OperatorFlowGraph/OperatorFlowGraph';
import { ApprovalConfig } from 'src/hooks/useApprovalDecision/useApprovalDecision';
import { StatusMessageModal } from 'src/components/modals/StatusMessageModal';
import { KatButton } from '@amzn/katal-react';
import { useQueryClient } from '@tanstack/react-query';
import { OperatorHelpModal } from 'src/components/modals/OperatorHelpModal';

export const OperatorFlowVisualizationContainer = ({
  operatorFlowDetails,
  operatorFlowDataLoading,
}: {
  operatorFlowDetails?: OperatorFlowJob;
  operatorFlowDataLoading: boolean;
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(true);
  const [approvalConfig, setApprovalConfig] = useState<ApprovalConfig | null>(
    null,
  );
  const [promoteConfig, setPromoteConfig] = useState<PromoteConfig | null>(
    null,
  );
  const [redeployConfig, setRedeployConfig] = useState<RedeployConfig | null>(
    null,
  );
  const [statusModalMessage, setStatusModalMessage] = useState<{
    message: string;
    name: string;
  } | null>(null);
  const [retryConfig, setRetryConfig] = useState<RetryConfig | null>(null);
  const [operatorHelpConfig, setOperatorHelpConfig] = useState<{
    status: OperatorJobStatus;
  } | null>(null);
  const [
    pipelineGraphLoading,
    pipelineGraphError,
    pipelineGraphData,
    getPipelineGraph,
  ] = useGetSageMakerPipelineGraph();

  const [selectedPanel, setSelectedPanel] = useState<string>('panel-1');

  const toggleFullScreen = () =>
    document.fullscreenElement
      ? document.exitFullscreen()
      : document
          .querySelector('.operator-flow-visualization-container')
          ?.requestFullscreen();

  const togglePanel = ({ panelId }: { panelId: string }) => {
    setIsPanelOpen(true);
    setSelectedPanel(panelId);
  };

  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  if (!operatorFlowDataLoading && !operatorFlowDetails?.graphDetails) {
    return <p>No graph data available.</p>;
  }
  return (
    <div className="operator-flow-visualization-container">
      <div className="operator-flow-visualization__meta-controls">
        <KatButton
          variant="secondary"
          loading={loading || operatorFlowDataLoading}
          onClick={() => {
            setLoading(true);
            queryClient.invalidateQueries([
              'operator-flow-data',
              operatorFlowDetails?.id,
            ]);
            // < 100ms request, so delay here to show something happening in the ui
            setTimeout(() => {
              setLoading(false);
            }, 500);
          }}
        >
          Refresh
        </KatButton>
      </div>
      <button
        className={cx('operator-flow-visualization-container__overlay', {
          'operator-flow-visualization-container__overlay--hidden':
            !isOverlayVisible,
        })}
        onClick={() => {
          setIsOverlayVisible(false);
        }}
      >
        Click or Tap to Interact
      </button>

      <OperatorFlowGraph
        operatorFlowDetails={operatorFlowDetails}
        getPipelineGraph={getPipelineGraph}
        setPromoteConfig={setPromoteConfig}
        setRedeployConfig={setRedeployConfig}
        setApprovalConfig={setApprovalConfig}
        setStatusModalMessage={setStatusModalMessage}
        setRetryConfig={setRetryConfig}
        setOperatorHelpConfig={setOperatorHelpConfig}
        goFullScreen={toggleFullScreen}
        togglePanel={togglePanel}
      />
      <ApprovalDecisionModal
        approvalConfig={approvalConfig}
        setApprovalConfig={setApprovalConfig}
      />
      <PromoteModal
        promoteConfig={promoteConfig}
        setPromoteConfig={setPromoteConfig}
      />
      <RedeployModal
        redeployConfig={redeployConfig}
        setRedeployConfig={setRedeployConfig}
      />
      <RetryModal config={retryConfig} setConfig={setRetryConfig} />
      <OperatorHelpModal
        helpConfig={operatorHelpConfig}
        setHelpConfig={setOperatorHelpConfig}
      />
      <StatusMessageModal
        statusMessage={statusModalMessage}
        setStatusMessage={setStatusModalMessage}
      />
      <SlideOutPanel
        isOpen={isPanelOpen}
        closePanel={() => {
          setIsPanelOpen(false);
        }}
      >
        <Tabs
          selectedPanel={selectedPanel}
          setSelectedPanel={setSelectedPanel}
          focusable={isPanelOpen}
        >
          <Tab label="SageMaker Pipeline Graph" id="panel-2">
            {isPanelOpen && (
              <SageMakerPipelineGraph
                data={pipelineGraphData}
                loading={pipelineGraphLoading}
                error={pipelineGraphError}
              />
            )}
          </Tab>
        </Tabs>
      </SlideOutPanel>
    </div>
  );
};
