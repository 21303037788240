import React from 'react';

import { TrainingJobCell } from 'src/components/table-cells/TrainingJobCell/TrainingJobCell';

export const TrainingJobColumn = (
  accountNo: string,
  applicationId: string,
  modelVersionId: string,
  modelArtifactId: string,
) => {
  const TrainingJobColumnCell = (cellInfo: any) => (
    <TrainingJobCell
      trainingJobArn={cellInfo.row.original.trainingJobArn}
      id={cellInfo.value}
      state={{
        accountNo,
        applicationId,
        modelVersionId,
        modelArtifactId,
      }}
    />
  );

  return {
    Header: 'Training Job',
    Cell: TrainingJobColumnCell,
    accessor: 'id',
    sortable: true,
    width: 250,
  };
};
