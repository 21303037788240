export const initialState = {
  apiData: {
    notificationEmailAddresses: [],
    modelArtifacts: {
      data: [],
      error: '',
    },
    modelVersions: {
      data: [],
      error: '',
    },
    modelVersionEvents: {
      data: [],
      error: '',
    },
    applications: {
      data: [],
      error: '',
    },
    trainingJobs: {
      data: [],
      error: '',
    },
    algorithmMetrics: {
      data: [],
      error: '',
    },
    dataQualityMetrics: {
      data: [],
      error: '',
    },
    operatorFlowJobs: {
      data: [],
      error: '',
    },
    search: {
      accounts: {
        data: [],
        error: '',
      },
      onboardableAccounts: {
        data: [{ name: 'Loading Accounts...', id: '007' }],
        error: '',
      },
      workflowOnboardingRequestStatus: {
        state: 0,
        statusMessage: '',
      },
      curOnboardableAccountNo: '',
      curRoleName: 'MLRollerAccessRole',
      curAccountNo: '',
      curApplicationId: '',
      curModelVersionId: '',
      curModelVersionEventId: '',
      curModelArtifactId: '',
      curTrainingJobId: '',
      curOperatorFlowJobId: '',
      curOperatorFlowJobStartTime: 0,
      curOperatorFlowJobEndTime: 0,
    },
    toasts: [],
  },
  user: {},
};
