import React from 'react';
import { KatButton, KatIcon, KatLink, KatModal } from '@amzn/katal-react';

interface Props {
  helpConfig: { status: OperatorJobStatus } | null;
  setHelpConfig: (arg: { status: OperatorJobStatus } | null) => void;
}

export const OperatorHelpModal = ({ helpConfig, setHelpConfig }: Props) => {
  return (
    <KatModal
      title="Common Issues"
      visible={helpConfig !== null}
      onClose={() => setHelpConfig(null)}
      footer={
        <KatButton
          variant="primary"
          onClick={() => {
            setHelpConfig(null);
          }}
        >
          Close
        </KatButton>
      }
    >
      <ul>
        {helpConfig?.status === 'PENDING' && (
          <div>
            <p>
              If the operator is stuck in &apos;Pending&apos; status after the
              SageMaker job/endpoint is completed or failed in your AWS account,
              it could be caused by:
            </p>
            <ul>
              <li>
                Incomplete or incorrect setup in your AWS account. If MLRoller
                did not receive your SageMaker event update, we cannot know the
                status of the SageMaker job, hence the operator node and the
                operator flow job remains in Pending status. Follow this FAQ to
                troubleshoot:
                <KatLink
                  className="link link--icon link--inline"
                  href="https://tiny.amazon.com/g5c69f9g/wikikbobdispKuip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tiny.amazon.com/mjqc07lc/wamazbinviewMLPi
                  <KatIcon name="launch" size="tiny" />
                </KatLink>
              </li>
              <li>
                If it happens in a ModelDeployOperator with SageMaker endpoint
                update, the issue could be caused by a failure to update the
                endpoint with the latest endpoint config, and SageMaker endpoint
                auto-rollback to the previous valid endpoint config. You can
                verify this situation by comparing whether the following two
                endpoint config names are the same:
                <ol>
                  <li>
                    Check the endpoint config that was created during this
                    workflow, click &apos;View Logs&apos; and search for
                    &apos;EndpointConfigName&apos; to get the name
                  </li>
                  <li>
                    Navigate to AWS SageMake console, check the endpoint detail
                    and find the endpoint config that was actually deployed to
                    the endpoint.
                  </li>
                </ol>
                <p>
                  If the two endpoint config names are different, it likely
                  means that there was an issue with your model or endpoint
                  config that caused SageMaker endpoint failed to deploy with
                  the latest endpoint config. Checking SageMake endpoint
                  CloudWatch log in your AWS account could also be helpful.
                </p>
              </li>
            </ul>
          </div>
        )}
        {helpConfig?.status === 'FAILED' && (
          <div>
            <p>
              If the operator is in &apos;Failed&apos; status, clicking the
              &apos;View Logs&apos; button will show you the CloudWatch logs
              from the operator run, which might contain an error:
            </p>
            <ul>
              <li>
                Search for keywords in the log, such as &apos;error&apos; or
                &apos;exception&apos;. The failure root cause is usually near
                the END of the log.
              </li>
              <li>
                If you see an error message such as &apos;AlgorithmError: See
                job logs for more information&apos;, the issue is from your
                SageMaker job. Please use AWS console to check the CloudWatch
                log of the corresponding SageMaker job in your AWS account.
                <p>
                  MLRoller provides direct console links to your SageMaker job
                  (&apos;View Sagemaker XXX Job&apos; in operator flow job
                  diagram), but MLRoller does not have access to the detailed
                  SageMaker job CloudWatch log.
                </p>
              </li>
            </ul>
          </div>
        )}
        {helpConfig?.status === 'NOTSTARTED' && (
          <div>
            <p>
              If the operator previous to this one is in &apos;Succeeded&apos;
              status, but this operator is still &apos;Not Started&apos; after
              some time, it could be caused by a known issue:
            </p>
            <p>
              Failure to create a new SageMaker job may cause failure to update
              operator node status and operator flow job status.
            </p>
            <p>
              Click &apos;View Logs&apos; of the last &apos;Succeeded&apos;
              operator, and search for keywords, such as &apos;error&apos; or
              &apos;exception&apos; to find the failure reason. The root cause
              of why the downstream operator failed to update (and stuck in
              &apos;Not Started&apos; status) is usually near the END of the
              log.
            </p>
          </div>
        )}
      </ul>
    </KatModal>
  );
};
