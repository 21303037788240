import Auth from '../auth/cognito';

const auth = new Auth();
const stage = auth.getStage();

export const baseURL = `https://${
  stage.startsWith('prod') ? 'yxu5i02h1a.execute-api.us-east-1' : 'od3x0a3rxh.execute-api.us-west-2'
}.amazonaws.com/prod`;

export const urlList = {
  accounts: `${baseURL}/accounts`,
  customerEventQueueSubscriptions: `${baseURL}/customerEventQueueSubscriptions`,
  onboardableAccounts: `${baseURL}/onboardableAccounts`,
  applications: `${baseURL}/applications`,
  modelVersions: `${baseURL}/modelVersions`,
  modelVersionEvents: `${baseURL}/modelVersionEvents`,
  modelArtifacts: `${baseURL}/modelArtifacts`,
  trainingJobs: `${baseURL}/trainingJobs`,
  monitoringSchedules: `${baseURL}/monitoringSchedules`,
  modelMetrics: `${baseURL}/modelMetric`,
  eventCommand: `${baseURL}/eventCommand`,
  cliOnboardingToken: `${baseURL}/codeArtifact/readOnlyToken`,
  workflowOnboarding: `${baseURL}/workflowOnboarding`,
  triggerBatchInferenceJob: `${baseURL}/trigger/batchInferenceJob`,
  triggerTrainingJob: `${baseURL}/trigger/trainingJob`,
  triggerWorkflow: `${baseURL}/trigger/workflow`,
  operatorFlowJobs: `${baseURL}/operatorFlowJobs`,
  approvalOperatorStatus: `${baseURL}/approvalOperatorStatus`,
  embeddedDashboard: `${baseURL}/metricsDashboard`,
  graphNodeLogs: `${baseURL}/graphNodeLogs`,
  jobDetails: `${baseURL}/jobDetails`,
  templateUrl: `${baseURL}/templateUrl`,
  triggerDeploymentRollback: `${baseURL}/trigger/deploymentRollback`,
  deploymentJobs: `${baseURL}/deploymentJobs`,
  operatorAction: `${baseURL}/trigger/operatorAction`,
  sharedModels: `${baseURL}/sharedModels`,
};
