import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';

/* istanbul ignore next */
const metricsConsoleErrorHandler = (err: Error) => console.error(err);

export const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  }

  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm('prod', 'USAmazon')
    .withErrorHandler(metricsConsoleErrorHandler)
    .build();
};

const makePublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('MLRollerWebsite')
    .withServiceName('MLRoller')
    .build();
  return new KatalMetrics.Publisher(
    metricsDriver,
    metricsConsoleErrorHandler,
    initialMetricsContext,
  );
};

export const initialMetricsPublisher = makePublisher();
