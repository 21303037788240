import { useState } from 'react';
import { apiGetRequest } from 'src/utils/mlRollerAPIRequest/mlRollerAPIRequest';
import { SageMakerPipelineGraphData } from 'src/components/SageMakerPipelineGraph/SageMakerPipelineGraph';

export const useGetSageMakerPipelineGraph = (): [
  boolean,
  Error | null,
  SageMakerPipelineGraphData | null,
  ({ jobId }: { jobId: string }) => void,
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<SageMakerPipelineGraphData | null>(null);

  const getPipelineGraph = ({ jobId }: { jobId: string }) => {
    setLoading(true);
    setError(null);
    setData(null);

    return apiGetRequest({
      endpoint: `sageMakerPipelineExecutionDefinitions/${jobId}`,
    })
      .then((res) => {
        setData({
          steps: JSON.parse(res.response).Steps,
          jobId,
        });
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };

  return [loading, error, data, getPipelineGraph];
};
