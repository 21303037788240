import React, { useEffect, useState } from 'react';
import { KatButton, KatInput, KatModal } from '@amzn/katal-react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import {
  createApplication,
  deleteApplication,
  editApplication,
} from 'src/hooks/useApplications';
import { buildArn, getRoleName } from 'src/utils/generalUtils/generalUtils';
import { useNavigate } from 'react-router-dom';

export const ApplicationModal = ({
  account,
  modalMode,
  values,
  onClose,
  visible,
  redirectLocation,
}: {
  account: string;
  modalMode: 'New' | 'Edit' | 'Delete';
  values?: {
    id?: string;
    name: string;
    description?: string;
    s3ScriptFileLocation: string;
    roleName?: string;
    roleArn: string;
    roleExternalId?: string;
    version?: number;
  };
  onClose: () => void;
  visible: boolean;
  redirectLocation?: string;
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const ApplicationValidationSchema = yup.object().shape({
    name: yup.string().required('Please specify an application name.'),

    s3ScriptFileLocation: yup
      .string()
      .required('Please supply the location of the userscript file.'),

    roleName: yup
      .string()
      .matches(
        /^[\w+=,.@-]+$/g,
        'The role name may only consist of letters, digits or the following special characters: _+=,.@-',
      )
      .min(1, 'The role name must be at least one character.')
      .max(64, 'The role name may not be longer than 64 characters.')
      .required(
        'Please supply the name of the MLRoller IAM Access role to be used with this application.',
      ),
  });

  const initialValues = {
    name: values?.name || '',
    s3ScriptFileLocation: values?.s3ScriptFileLocation || '',
    description: values?.description || '',
    roleName: values?.roleArn ? getRoleName(values?.roleArn) : '',
    roleExternalId: values?.roleExternalId || '',
  };

  useEffect(() => {
    formik.setValues({
      name: values?.name || '',
      s3ScriptFileLocation: values?.s3ScriptFileLocation || '',
      description: values?.description || '',
      roleName: values?.roleArn ? getRoleName(values?.roleArn) : '',
      roleExternalId: values?.roleExternalId || '',
    });
    formik.setTouched({});
  }, [values]);

  const [error, setError] = useState<Error | null>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: async (formValues) => {
      let response;
      // check the mode, call the correct api
      setLoading(true);
      const roleArn = buildArn(account, formValues.roleName);
      switch (modalMode) {
        case 'New':
          response = await createApplication(
            {
              application: Object.assign({ ...formValues }, { roleArn }),
              account: account,
            },
            queryClient,
          )
            .catch((e) => {
              setError(e);
              return e.message;
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case 'Edit':
          if (!values?.id) return;
          response = await editApplication(
            {
              id: values?.id,
              application: Object.assign({ ...formValues }, { roleArn }),
              account: account,
              version: (values?.version || 1) + 1,
            },
            queryClient,
          )
            .catch((e) => {
              setError(e);
              return e.message;
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }

      if (!response) onClose();
    },
    validationSchema: ApplicationValidationSchema,
  });

  const handleDeleteConfirm = async () => {
    if (!values?.id) return;
    setLoading(true);
    const response = await deleteApplication(
      {
        id: values?.id,
        account,
      },
      queryClient,
    )
      .catch((e) => {
        setError(e);
        return e.message;
      })
      .finally(() => {
        setLoading(false);
        if (redirectLocation) {
          navigate(redirectLocation);
        }
      });

    if (!response) onClose();
  };

  return (
    <KatModal
      visible={visible}
      onClose={onClose}
      title={`${modalMode} Application`}
      footer={
        <div className="modal-footer">
          {error && <p className="error-text">{error.message}</p>}
          <KatButton variant="link" onClick={onClose}>
            Cancel
          </KatButton>
          <KatButton
            loading={loading}
            variant={modalMode === 'Delete' ? 'danger' : 'primary'}
            onClick={() => {
              modalMode === 'Delete'
                ? handleDeleteConfirm()
                : formik.handleSubmit();
            }}
          >
            {modalMode === 'Delete' ? 'Delete Application' : 'Submit'}
          </KatButton>
        </div>
      }
    >
      {modalMode === 'Delete' ? (
        <p>This will delete the Application. Confirm?</p>
      ) : (
        <div className="modal-form">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <KatInput
                label="Application Name"
                value={values?.name || formik.values.name}
                placeholder="My MLRoller Application"
                name="name"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({ ...formik.touched, name: true });
                }}
                state={
                  formik.touched.name && formik.errors.name
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.name}
              />
              <KatInput
                label="Description"
                value={values?.description || formik.values.description}
                name="description"
                placeholder="My MLRoller application description"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({ ...formik.touched, description: true });
                }}
                state={
                  formik.touched.description && formik.errors.description
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.description}
              />
              <KatInput
                label="Userscript S3 Location"
                value={
                  values?.s3ScriptFileLocation ||
                  formik.values.s3ScriptFileLocation
                }
                name="s3ScriptFileLocation"
                placeholder="s3://bucket-name/path/user_script.py"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({
                    ...formik.touched,
                    s3ScriptFileLocation: true,
                  });
                }}
                state={
                  formik.touched.s3ScriptFileLocation &&
                  formik.errors.s3ScriptFileLocation
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.s3ScriptFileLocation}
              />
              <KatInput
                label="Access IAM Role Name"
                value={
                  values?.roleArn
                    ? getRoleName(values?.roleArn)
                    : formik.values.roleName
                }
                name="roleName"
                placeholder="MLRollerAccessRole"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({ ...formik.touched, roleName: true });
                }}
                state={
                  formik.touched.roleName && formik.errors.roleName
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.roleName}
              />
              <KatInput
                label="Access IAM Role External Id"
                value={values?.roleExternalId}
                name="roleExternalId"
                placeholder="RoleId"
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({
                    ...formik.touched,
                    roleExternalId: true,
                  });
                }}
                state={
                  formik.touched.roleExternalId && formik.errors.roleExternalId
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.roleExternalId}
              />
            </form>
          </div>
        </div>
      )}
    </KatModal>
  );
};
