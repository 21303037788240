import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KatButton, KatModal } from '@amzn/katal-react';
import {
  RetryConfig,
  useRetryOperator,
} from 'src/hooks/useRetryOperator/useRetryOperator';
import { ApiContext } from 'src/context/apiContext';
import startCase from 'lodash/startCase';

export const RetryModal = ({
  config,
  setConfig,
}: {
  config: RetryConfig | null;
  setConfig: Dispatch<SetStateAction<RetryConfig | null>>;
}) => {
  const { retry } = useRetryOperator();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  const { dispatch } = useContext(ApiContext);

  if (!config) return <></>;
  const friendlyAction = startCase(config.action);
  return (
    <KatModal
      title={`${friendlyAction} ${config.operatorName} Operator?`}
      visible={!!config}
      onClose={() => {
        setConfig(null);
      }}
      footer={
        <>
          {error && <p className="error-text">{error.message}</p>}
          <KatButton
            variant="link"
            onClick={() => {
              setConfig(null);
            }}
          >
            Cancel
          </KatButton>
          <KatButton
            onClick={() => {
              setLoading(true);
              setError(null);
              retry(config)
                .then(() => {
                  dispatch({
                    type: 'addToast',
                    payload: {
                      header: `Processing Request`,
                      description: `The ${friendlyAction} request for ${config.operatorName} is being processed. This page will refresh in one minute.`,
                      variant: 'success',
                      dismissTime: 60000,
                      onTimeout: () => {
                        navigate(0);
                      },
                    },
                  });
                  setConfig(null);
                })
                .catch(setError)
                .finally(() => {
                  setLoading(false);
                });
            }}
            loading={loading}
            label={friendlyAction}
          />
        </>
      }
    >
      {config.action === 'retry' && (
        <>
          <p>
            If you updated the user script, please trigger a new operator flow
            job. Operator node retry button will <strong>NOT</strong> run with
            the updated user script, instead, it is using the user script
            snapshot that was created when this current operator flow job was
            triggered.
          </p>
          <p>
            Before you retry the failed operator node, please check the log of
            the failed operator node by clicking “View Logs” button and search
            for “error” or “exception” keywords for further investigation.
          </p>
          <p>
            If you see error messages similar to “AlgorithmError: See job logs
            for more information”, you will need to check the Cloudwatch log of
            the corresponding SageMaker job in your own AWS account for log
            information.
          </p>
          <p>
            After retrying the operator node, please allow 1-2 minutes before
            the updated status show up on MLRoller portal.
          </p>
        </>
      )}
    </KatModal>
  );
};
